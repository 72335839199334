enum BreakPoints {
    xs10 = 'xs10',
    xs9 = 'xs9',
    xs8 = 'xs8',
    xs7 = 'xs7',
    xs6 = 'xs6',
    xs5 = 'xs5',
    xs4 = 'xs4',
    xs3 = 'xs3',
    xs2 = 'xs2',
    xs = 'xs',
    sm = 'sm',
    md = 'md',
    lg = 'lg',
    xl = 'xl',
    xl2 = 'xl2',
    xl3 = 'xl3',
    xl4 = 'xl4',
    xl5 = 'xl5',
    xl6 = 'xl6',
    xl7 = 'xl7',
    xl8 = 'xl8',
    xl9 = 'xl9',
    xl10 = 'xl10',
  }

export default BreakPoints
